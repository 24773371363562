import styled from 'styled-components'

const PodcastCardStyle = styled.div`
  .card {
    background-color: var(--darkBlue);
    cursor: pointer;
    transition: transform 0.15s ease;
    border: 1px solid var(--fadedWhite);

    &:hover {
      transform: scale(1.025);
    }
  }
  .card-image {
    height: 0;
    padding-top: 75%;
    border-bottom: 1px solid var(--fadedWhite);
  }
  .caption {
    line-height: 1rem;
    border-left: 2px solid var(--secondary);
    border-bottom: 2px solid var(--secondary);
    font-size: 0.8rem;
  }
  .title {
    background: var(--primary);
    padding: 0.25rem;
    display: inline-block;
  }
`;

export default PodcastCardStyle